.login-pane {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #E9ECEF;
  height: 100vh;
  padding-bottom: 20px;
}

.login-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
}

.login-text-input {
  font-size: 12px;
}

.login-button {
  background-color: #212529 !important;
  color: #F8F9FA !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  height: 40px !important;
  width: 100% !important;
}

.login-button:hover {
  background-color: rgba(33, 37, 41, 0.8) !important;
  color: #F8F9FA;
  font-weight: 600;
  font-size: 17px;
  height: 40px;
  width: 100%;
}

.register-text {
  margin-top: 2rem;
  font-weight: 300;
  font-family: 'Rubik-Light';
  text-align: center;
}

.forgot-password-text {
  font-weight: 300;
  font-family: 'Rubik-Light';
  text-align: center;
}

.reset-password-link {
  font-family: 'Rubik-Bold';
  text-decoration: none;
  color: #000
}

@media (min-width: 720px) {
  .login-card {
    padding: 30px 50px;
    width: 536px;
    min-height: 650px;
  }

  .forgot-password-text {
    margin-top: .5rem;
  }
}