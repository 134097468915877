/* Sensor Readings */

.sensor-reading-label {
  color: #636D77;
  font-size: 17px;
  font-family: "Rubik-Light";
  font-weight: 300;
}

.sensor-reading-value {
  margin: 0;
  font-family: "Rubik-Bold";
  color: #292D33;
  font-size: 35px; 
}

.sensor-reading-value sup {
  margin: 0;
  font-family: "Rubik-Light";
  color: #636D77;
  font-size: 14px; 
}

.sensor-reading-date {
  margin: 16px;
  font-family: "Rubik-Light";
  color: #636D77;
  font-size: 14px; 
}


/* Device info Card */
.device-info-card .info-panel-label {
  flex: 1;
  margin: 0;
  font-size: 14px; 
  font-family: "Rubik-Bold"
}

.device-info-card .info-panel-value {
  text-align: left;
  color: #424851;
  flex: 1;
  margin: 0;
  font-size: 14px;
  font-family: "Rubik-Light"
}

.info-panel-description-text {
  flex: 1;
  margin: 0;
  padding-top: 0.8rem;
  font-size: 14px;
  color: #424851;
  font-family: "Rubik-Light"
}

.info-panel-area-value {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

h3 {
    margin: 0;
    font-size: 24px;
    color: #292D33;
}