
.settings-sidebar ul > li:first-child {
  list-style: none;
  padding: 0.4rem 0 0.4rem 0;
  margin-right:0.4rem;
  margin-top: 0rem;
}
.settings-sidebar ul > li {
  list-style: none;
  padding: 0.4rem 0 0.4rem 0;
  margin-right:0.4rem;
  margin-top: 1rem;
}

.settings-sidebar ul > li > a {
  text-decoration: none;
}

.settings-sidebar ul > li > a:visited {
  color: #292D33;
}

.settings-sidebar ul > li.active {
  list-style: none;
  padding: 0.4rem 0 0.4rem 0;
  margin-right:0.4rem;
  border-right:2px solid #008F51;
}