.icon-tab {
  display: flex;
  width: 48px;
  padding: 1rem;
  transition: width 0.3s linear;
}

.hidden {
  display: none;
}

.icon-tab .label {
  margin:0;
  flex: 1;
  font-size: 12px;
  color: #fff;
  transition: display 1s;
}

.icon-tab .label.active {
  margin:0;
  flex: 1;
  color: rgba(48, 55, 72, 0.8);
}

.icon-tab.expanded-tab {
  flex-direction: row;
  justify-content: space-between; 
  width: 160px;
}

.icon-tab:hover {
  background-color:rgba(48, 55, 72, 0.8)
}

.icon-tab.active {
  background-color: #F1F2F6;
}

.align-center {
  align-items: center;
}