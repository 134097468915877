.landing_top_nav {
  display: grid;
  grid-template-areas: "logo cta menu";
  gap: 0.5rem;
  padding: 0.5rem 2rem;
  background: rgba(255, 205, 187, 1);
  position: relative;
  align-items: center;
  justify-items: start;
}

.landing_top_nav a {
  color: #000000;
  padding: 0.6rem;
  white-space: nowrap;
}

.landing_top_nav a:hover {
  background: rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  filter: brightness(200%);
}

.button {
  background-color: #2b313f !important;
  color: #ffffff !important;
  border-color: #2b313f;
}

.button:hover {
  background-color: #2b313f !important;
  color: #ffffff;
  border-color: #2b313f !important;
  filter: brightness(150%);
  transition: all 0.3s ease-in-out;
}

.button_outline {
  background-color: transparent;
  color: #000000;
  border-color: #2b313f;
  margin-right: 5px;
}

.button_outline:hover {
  background-color: #fafbff !important;
  color: #000000;
  border-color: #2b313f !important;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.landing_top_nav a:visited,
.landing_top_nav a:-webkit-any-link {
  text-decoration: none;
}

.nav_desktop {
  display: none;
}

.nav_mobile {
  display: flex;
  flex-direction: column;
}

.nav_mobile.landing_links {
  position: absolute;
  background: #ffffff;
  top: 3.5rem;
  right: 1rem;
  padding: 1.5rem;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.09);
}

@media (min-width: 768px) {
  .nav_desktop {
    display: flex;
    align-items: center;
  }

  .nav_mobile {
    display: none !important;
  }

  .landing_top_nav {
    grid-template-columns: 3rem repeat(3, 1fr) 3rem;
    grid-template-areas: ". nav nav nav .";
    gap: 0.5rem;
    padding: 1rem 2rem;
  }

  @media (min-width: 1024px) {
    .landing_top_nav {
      grid-template-columns: 1fr repeat(3, minmax(260px, 1fr)) 1fr;
      grid-template-areas: ". logo nav nav .";
    }

    nav {
      justify-self: center;
    }
  }
}
