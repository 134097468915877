/* Devices List/Dashboard Styles */

.device-card-label {
  color: #636D77;
  font-size: 17px;
  font-family: "Rubik-Light";
  font-weight: 300;
  margin-top: 0px;
}

.device-card-value {
  margin: 0;
  font-family: "Rubik-Bold";
  color: #292D33;
  font-size: 35px; 
}