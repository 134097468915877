/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(./fonts/Rubik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik-Bold';
  src: local('Rubik-Bold'), url(./fonts/Rubik-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik-Light';
  src: local('Rubik-Light'), url(./fonts/Rubik-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Light'), url(./fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./fonts/Roboto-Medium.ttf) format('truetype');
}

html {
  font-size: 16px;
}

h1, .h1 {
  font-size: 2.5rem;
  font-family: 'Rubik-Bold';
  color: #292D33;
}

h2, .h2 {
  font-size: 1.5rem;
  font-family: 'Rubik-Bold';
  color: #292D33;
}

h3, .h3 {
  font-size: 1.2rem;
  font-family: 'Rubik';
  color: #292D33;
}

h4, .h4 {
  font-size: 1rem;
  font-family: 'Rubik';
  color: #292D33;
}

h5, .h5 {
  font-size: 0.8rem;
  font-family: 'Rubik';
  color: #292D33;
}

h6, .h6 {
  font-size: 0.7rem;
  font-family: 'Rubik';
  color: #292D33;
}

p, .p {
  font-family: 'Roboto';
  color: #525C7A;
}

body {
  margin: 0;
  font-family: 'Rubik', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.auto-column {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.margin-0 {
  margin: 0;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.reg {
  font-weight: 400;
}

.md-hidden {
  display: unset;
}

.lg-hidden {
  display: unset;
}
