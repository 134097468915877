.registration-pane {
  display: flex;
  background-color:#E9ECEF;
}

.registration-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: auto;
}

@media (min-width: 720px) {
  .registration-pane {
    height: 100vh;
  }

  .registration-card {
    padding: 30px 50px;
    width: 536px;
    min-height: 650px;
  }
}


.login-text-input {
  font-size: 12px;
}

.register-button {
  background-color: #212529 !important;
  color:#F8F9FA !important;
  font-weight: 600 !important;
  font-size:17px !important;
  height: 40px !important;
  width: 100% !important;
}

.register-button:hover {
  background-color: rgba(33, 37, 41, 0.8) !important;
  color:#F8F9FA;
  font-weight: 600;
  font-size:17px;
  height: 40px;
  width: 100%;
}

.login-text {
  margin-top: 2rem;
  font-weight: 300;
  font-family: 'Rubik-Light';
  text-align: center;
}

.forgot-password-text {
  margin-top: .5rem;
  font-weight: 300;
  font-family: 'Rubik-Light';
  text-align: center;
}

.reset-password-link {
  font-family: 'Rubik-Bold';
  text-decoration: none;
  color: #000
}