.device-card-onboard {
  display: flex;
  grid-column: 1/-1;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 1px rgb(67 90 111 / 30%), 0 2px 4px -2px rgb(67 90 111 / 47%);
  padding: 1em;
}

.device-card-expanded {
  flex: 1;
}

.code-completion-select {
  max-width: 30%;
  max-height: 2rem;
  min-height: 32px;
}

.device-card-onboard pre[class*="language-"] {
  font-size: 0.8rem;
  padding: 0.2rem;
  margin: 0;
  height: 250px;
}