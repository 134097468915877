.support-header {
  display: flex;
  flex-direction: row;
  max-height: 486px;
  background: #F8F1F5;
  padding: 80px;
}

.support-heading {
  color: #2B313F;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
}

support-header, h2 {
  font-family: 'Rubik';
  font-size: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.download-text {
  text-decoration: none;
  color: #4E9971;
  margin-left: 5px;
}

a.download-text {
  margin: 0;
}

.download-pdf {
  display: flex;
  align-items: center;
}

.support-image {
  width: 403px;
  height: 208px;
  margin-left: auto;
}

.support-container {
  padding: 80px;

  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: #202530;
}

.support-container p,
.support-container li,
.support-description {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: #202530;
}

@media (max-width: 720px) {
  .support-header {
    display: flex;
    flex-direction: column;
    padding: 60px 25px 40px 25px;
  }

  .support-container {
    padding: 40px 25px;
  }

  .support-container p,
  .support-container li,
  .support-container .support-description {
      font-size: 16px;
  }
}
