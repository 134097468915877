.links-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 124px;
}

.links-card {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo-container{
  width: 355px;
  height: 160px;
  margin-bottom: 40px;
}

.logo-container img{
  width: 100%;
  height: auto;
}

@media screen and (max-width: 576px) {
  .logo-container{
    width: 178px;
    height: 80px;
  }
}