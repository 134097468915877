.top-navigation {
  display: none;
}

@media (min-width: 768px) {
  .top-navigation {
    display: flex;
    flex: 1;
    min-height: 40px;
    align-items: center;
    justify-content: flex-end;
    background-color: #ffffff;
    padding-right: 1rem;
  }

  .top-navigation p {
    margin: 0;
    padding: 0;
  }

  .top-navigation .notification-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }

  .top-navigation .notification-link p {
    font-size: 10px;
    text-align: center;
    color: #9F2D64;
  }
}