.landing-primary-button {
  display:flex;
  align-items: center;
  justify-content: center;
  background: #008F51;
  border: 1px solid #008F51;
  border-radius: 8px;
  text-decoration: none;
  padding: 0.6rem;
  color: white;
  white-space: nowrap;
  max-width: 170px;
}

.landing-primary-button:hover {
  padding: 0.6rem;
  background-color:rgba(0, 143, 81, 0.75);
  border: 1px solid rgba(0, 143, 81, 0.7);
  white-space: nowrap;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}