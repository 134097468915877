.custom-tooltip {
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
  width: 200px;
  border: 1px solid lightgray;
  padding: 0.4rem;  
}

.custom-tooltip .label {
  font-size: 1.2rem;
  color: black;
  margin: 0;
  font-weight: 200;
}

.custom-tooltip .intro {
  font-size: 1rem;
}

