.banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #4E9971;
  box-sizing: border-box;
  overflow: hidden;
  transition: height 0.5s ease-out;
}

.banner.hidden {
  height: 0;
  transition: height 0.5s ease-out;
}

.banner-button {
  flex: 2;
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Rubik-Bold';
  padding: 0 55px;
  cursor: pointer;
}

.banner-text {
  font-size: 14px;
  color: white;
  font-family: 'Rubik-Bold';
}

.close-button {
  position: absolute;
  top: 5px; 
  right: 5px; 
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .banner {
    display: none;
  }
}
