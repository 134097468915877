.banner-desktop {
  display: none;
}

.hero-grid {
  display: grid;
  background: rgba(255, 205, 187, 1);
  grid-template-areas:
    ". hero-copy ."
    ". hero-animated-image ."
    ". app-download .";
  gap: 0.5rem;
  padding: 2rem 3.2rem;
}

.hero-copy {
  grid-area: hero-copy;
}

.app-download {
  grid-area: app-download;
}

.app-download-wrapper {
  display: flex;
  justify-content: center;
}

.hero-header {
  color: #2B313F;
  margin: 0;
  font-size: 1.75rem;
}

.hero-description {
  color: #2B313F;
  margin: 0;
  padding: 1rem 0rem;
  font-size: 1.2rem;
}

.app-download-align {
  text-align: center;
}

.email-input-wrapper {
  display: flex;
  height: 2.8rem;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  /* grid-template-columns: minmax(1.2fr, 270px) minmax(.8fr, 180px);
  grid-template-rows: 2.8rem; */
  background: white;
  border-radius: 8px;
  /* min-width: 300px;
  max-width: 450px; */
}


.email-input {
  padding-left: 1rem;
  width: 100%;
  height: inherit;
  border: none;
  border-radius: inherit;
}

.email-input:focus {
  outline: none;
  padding-left: 1rem;
  width: 100%;
  height: inherit;
  border: none;
  border-radius: inherit;
}

.email-input-wrapper .landing-primary-button {
  padding: 0.6rem;
  white-space: nowrap;
  font-size: 1rem;
}

.hero-animated-image {
  grid-area: hero-animated-image;
  width: 100%;
}

.why-section-grid {
  display: grid;
  grid-template-areas:
    "title"
    "header"
    "description"
    "garden-data-animation"
    "pricing-options"
    "data-ingestion-metric"
    "data-ownership-graphic";
  gap: 0.5rem;
  padding: 2rem 3.2rem;
  max-width: 420px;
  margin: 0 auto;
}

.why-section-title {
  color: #008F51;
  text-transform: uppercase;
  font-size: 1rem;
  grid-area: title;
}

.why-section-header {
  color: #2B313F;
  margin: 0;
  font-size: 1.75rem;
  grid-area: header;
}


.why-section-description {
  color: #2B313F;
  grid-area: description;
}

.garden-data-animation {
  grid-area: garden-data-animation;
}

.garden-data-animated-image {
  margin: 1rem auto;
  width: 100%;
}

.plant-animation {
  display: none;
}

.pricing-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1.5px solid #525C7A;
  padding: 1rem;
  border-radius: 16px;
  margin: 0.5rem 0;
  grid-area: pricing-options;
}

.pricing-options .landing-primary-button {
  width: 175px;
}

.data-ingestion {
  border: 1.5px solid #525C7A;
  padding: 1rem;
  border-radius: 16px;
  margin: 0.5rem 0;
  grid-area: data-ingestion-metric;
  display: grid;
  overflow: hidden;
  grid-template-columns: 100%;
  grid-template-rows: auto auto 180px;
  grid-template-areas:
    "header"
    "description"
    "image";
}

.sensor-data-bg {
  display: block;
  width: 500px;
  height: 390px;
  grid-area: image;
  transform: rotate(30deg) translate(-30%, -15%);
  z-index: -1;
}

.data-ownership {
  box-sizing: border-box;
  margin-top: 2rem;
  position: relative;
  padding: 3rem 1.5rem;
  width: 100%;
  background-color: #525C7A;
  grid-area: data-ownership-graphic;
  border-radius: 16px 16px 80px 16px;
}

.data-ownership * {
  z-index: 2;
  color: #FFFFFF;
  position: relative;
}

.logo-stamp {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, -30%);
  z-index: 1;
}

.how-does-it-work-grid {
  display: grid;
  grid-template-areas:
    'title'
    'steps';
  gap: 0.7rem;
  padding: 1rem 2rem;
  place-items: center;
  max-width: 420px;
  margin: 0 auto;
}

.how-does-it-work-grid h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}


.how-does-it-work-grid h3 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.subsection-header {
  color: #2B313F;
  font-size: 1.4rem;
  margin: 0;
  padding-top: 20px;
  grid-area: header;
}

.unstyled-link {
  color: #FFFFFF;
  font-size: .8rem;
}

.app-features-grid {
  display: grid;
  grid-template-areas:
    "header"
    "screenshot1"
    "description1"
    "screenshot2"
    "description2"
    "screenshot3"
    "description3";
  gap: 0.5rem;
  padding: 2rem 2rem;
  place-items: center;
}


.app-features-screencap {
  /* Placeholder for animation/image */
  width: 100%;
  aspect-ratio: 1.93;
  background-color: #DCDCDC;
  border: 1.5px solid #2B313F;
  border-radius: 16px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.app-features-screencap.inactive {
  /* Placeholder for animation/image */
  filter: blur(2px);
}

.app-features-grid * {
  max-width: 420px;
}

.pricing-grid {
  display: grid;
  grid-template-areas:
    "header"
    "free"
    "monthly-toggle"
    "paid";
  gap: 0.5rem;
  padding: 1rem 2rem;
  place-items: center;
  background-color: #FFCDBB;
}

.pricing-grid h3 {
  font-size: 1.5rem;
}

.pricing-grid p {
  font-size: 1.2rem;
}

.free-features-container, .paid-features-container {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  border: 1.5px solid #2B313F;
  border-radius: 16px;
  overflow: hidden;
  padding-top: 1rem;
}

.free-features-container {
  grid-area: free;
  background-color: #FFD8CA;
}

.paid-features-container {
  grid-area: paid;
  background-color: #2B313F;
}

.paid-features-container * {
  color: #FFFFFF;
}

.free-features-container *, .paid-features-container * {
  margin: 0.5rem;
  margin-left: 1rem;
}

.free-features-container .landing-primary-button,
.paid-features-container .landing-primary-button {
  width: fit-content;
  max-width: 275px;
  max-height: 42px;
  box-sizing: border-box;
}

.free-features {
  background-color: #FFCDBB;
}

.paid-features {
  background-color: #4C5670;
}

.plus-period {
  margin-left: 0;
  font-size: 1rem;
}

#plus-period-desc {
  margin: 0 0.5rem 1rem 1rem;
}

.free-features, .paid-features {
  width: 100%;
  margin: 1rem 0 0 0;
  padding-left: 0.5rem;
}

.free-features ul, .paid-features ul {
  list-style: none;
  margin: 0;
  padding: 1rem;
}

.free-features li, .paid-features li {
  margin: 0.5rem;
  margin-left: 2rem;
  text-indent: -2rem;
}

.free-features ul img, .paid-features ul img {
  width: 16px;
  height: 11px;
  vertical-align: center;
  margin: 0;
  margin-right: 1rem;
}

.feature-comparison-container {
  display: none;
}


.unstyled-link:visited, .unstyled-link:-webkit-any-link {
  text-decoration: none;
  color: #FFFFFF;
}

.steps-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.steps-card {
  display: grid;
  grid-template-areas:
    "header"
    "title"
    "button";
  box-sizing: border-box;
  background-color: #F7ECE1;
  border-radius: 16px;
  padding: 1rem;
  width: 100%;
  margin: 0.5rem 0;
}

.banner-desktop {
  display: none;
}

.steps-card p {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.steps-card-button {
  font-family: Rubik-Light;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 12px;
  height: 42px;
  width: 100%;
  color: #474d66;
  text-decoration: none;
  background-color: white;
  border-radius: 4px;
  transition: box-shadow 80ms ease-in-out;
  border: 1px solid #c1c4d6;
  vertical-align: middle;
  outline: none;
  white-space: nowrap;
}

.steps-card-button:hover {
  border: 1px solid #8f95b2;
  background-color: #FAFBFF;
}

.steps-card form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 110px;
  grid-area: description;
}

.steps-card h2 {
  font-weight: 400;
  font-size: 1rem;
  grid-area: header;
}

.steps-card h1 {
  font-weight: 400;
  font-size: 1.5rem;
  grid-area: title;
  margin: 1rem;
}

.sm-hidden {
  display: none;
}

.features-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 768px) {
  .hero-grid {
    grid-template-columns: 3rem repeat(3, 1fr) 3rem;
    grid-template-areas:
      ". hero-copy hero-copy hero-animated-image ."
      ". app-download app-download app-download .";
    padding-bottom: 4rem;
  }

  .app-download-align {
    text-align: none;
  }

  .banner-desktop {
    display: unset;
  }

  .why-section-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "title . . "
      "header header garden-data-animation "
      "description description garden-data-animation "
      "plant-animation plant-animation plant-animation "
      "pricing-options data-ingestion-metric data-ingestion-metric "
      "data-ownership-graphic data-ownership-graphic data-ownership-graphic";
    max-width: unset;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .plant-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #71B48D;
    height: 205px;
    grid-area: plant-animation;
    border-radius: 16px;
  }

  .data-ingestion {
    position: relative;
    max-width: 100%;
    overflow: visible;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas:
      "header header"
      "description image"
  }

  .sensor-data-bg {
    transform: rotate(15deg) translate(-35%, -35%);
    position: absolute;
  }

  .data-ownership {
    margin-top: 4rem;
    padding: 3rem 10rem 2rem 4rem;
    border-radius: 16px 16px 125px 16px;
  }

  .data-ownership p {
    font-size: 1.25rem;
  }

  .logo-stamp {
    width: 180px;
  }

  .how-does-it-work-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      ". title ."
      "steps steps steps";
    max-width: unset;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .steps-container {
    grid-area: steps;
    flex-direction: row;
    justify-content: space-around;
  }

  .steps-card {
    background: transparent;
    max-width: 225px;
    grid-template-rows: 40px 80px 125px 65px;
  }

  .app-features-grid {
    display: grid;
    grid-template-areas:
      "header header"
      "description1 screenshot1"
      "screenshot2 description2"
      "description3 screenshot3";
    gap: 2rem;
    padding: 2rem 2rem;
    place-items: center;
  }

  .sm-hidden {
    display: unset;
  }

  .md-hidden {
    display: none;
  }

  .pricing-grid {
    grid-template-columns: 1fr repeat(2, minmax(300px, 1.5fr)) 1fr;
    grid-template-areas:
      ". header header ."
      ". monthly-toggle monthly-toggle ."
      ". free paid ."
      ". comparison comparison .";
    align-items: stretch;
  }

  .pricing-grid * {
    border: none;
  }

  .free-features, .paid-features {
    height: 280px;
    align-self: end;
  }

  .feature-comparison-container {
    display: grid;
    grid-template-columns: 1fr minmax(600px, 3fr) 1fr;
    grid-template-areas:
      ". header  ."
      ". comp .";
    place-items: center;
    background-color: #FFCDBB;
    padding: 2rem 0 6rem 0;
  }

  .feature-comparison-table {
    grid-area: comp;
    display: inline-table;
    width: 100%;
    background-color: #FFCDBB;
    border-radius: 16px;
    overflow: hidden;
    table-layout: fixed;
  }

  .feature-comparison-table th {
    text-align: left;
    padding: 1rem;
    padding-left: 2rem;
  }

  .feature-comparison-table td {
    padding: 0.5rem 1rem 0.5rem 1.5rem;
  }


  .feature-comparison-table td:nth-child(3n+1) {
    background-color: #FFBCA4;
  }

  .feature-comparison-table td:nth-child(3n + 2) {
    background-color: #FFC7B4;
  }

  .feature-comparison-table td:nth-child(3n + 3) {
    background-color: #3D4762;
    color: #FFFFFF;
  }

  .feature-comparison-table th:nth-child(3n+1) {
    background-color: #FFBCA4;
  }

  .feature-comparison-table th:nth-child(3n + 2) {
    background-color: #FFC7B4;
  }

  .feature-comparison-table th:nth-child(3n + 3) {
    background-color: #3D4762;
    color: #FFFFFF;
  }

  .feature-comparison-table tr:nth-child(2n) {
    filter: brightness(105%);
  }

  .feature-comparison-table th {
    filter: brightness(105%);
  }

}

@media (min-width: 1024px) {
  .sm-hidden {
    display: unset;
  }

  .email-input-wrapper {
    max-width: 420px;
  }

  .hero-grid {
    grid-template-columns: 1fr repeat(3, minmax(260px, 1fr)) 1fr;
    padding-bottom: 2rem;
  }

  .hero-description {
    font-size: 1.5rem;
  }

  .why-section-grid {
    grid-template-columns: 1fr repeat(3, minmax(260px, 1fr)) 1fr;
    grid-template-areas:
      ". title . . ."
      ". header header garden-data-animation ."
      ". description description garden-data-animation ."
      ". plant-animation plant-animation plant-animation ."
      ". pricing-options data-ingestion-metric data-ingestion-metric ."
      ". data-ownership-graphic data-ownership-graphic data-ownership-graphic .";
    margin-left: 0;
    margin-right: 0;
  }

  .data-ingestion {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas:
      "header header"
      "description image"
  }

  .data-ownership p {
    font-size: 1.4rem;
  }

  .how-does-it-work-grid {
    grid-template-columns: 1fr repeat(3, minmax(260px, 1fr)) 1fr;
    grid-template-areas:
      ". . title . ."
      ". steps steps steps .";
  }

  .steps-card {
    display: grid;
    height: 250px;
    max-width: 100%;
    grid-template-rows: 20px 50px 150px 50px;
    grid-template-areas: "header"
      "title"
      "description"
      "button";
    justify-items: start;
    align-items: center;
    padding: 1rem;
  }

  .steps-card h2, .steps-card h1 {
    justify-self: start;
    margin-bottom: 2rem;
    white-space: nowrap;
  }

  .steps-description {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 1rem;
    max-height: 150px;
  }


  .app-features-grid {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(390px, 1.5fr)) 1fr;
    grid-template-areas:
      ". header header ."
      ". description1 screenshot1 ."
      ". screenshot2 description2 ."
      ". description3 screenshot3 .";
  }

  .app-features-screencap {
    width: 100%;
  }

  .free-features-container, .paid-features-container {
    border: 1.5px solid #2B313F;
    border-radius: 16px;
  }

  .feature-comparison-container {
    display: grid;
    grid-template-columns: 1fr minmax(780px, 3fr) 1fr;
    grid-template-areas:
      ". header  ."
      ". comp .";
    place-items: center;
    background-color: #FFCDBB;
    padding: 2rem 0 6rem 0;
  }

  .feature-comparison-table {
    border: 1.5px solid #2B313F;
  }

  .feature-comparison-table td {
    padding: 1rem;
    padding-left: 2rem;
  }

  .feature-comparison-table th {
    border-bottom: 1.5px solid #2B313F;
  }

  .feature-comparison-table th+th {
    border-left: 1.5px solid #2B313F;
  }

  .feature-comparison-table td+td {
    border-left: 1.5px solid #2B313F;
  }

  .lg-hidden {
    display: none;
  }

}