.links-pane {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.links-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  text-decoration: none;
  margin: 20px;
  padding: 0.6rem;
  color: white;
  white-space: nowrap;
  width: 450px;
  height: 60px;
  font-size: 20px;
  font-family: 'Roboto-Medium';
}

.links-primary-button {
  background: #4E9971;
}

.links-primary-button:hover {
  background-color:rgba(0, 143, 81, 0.75);
}

.links-secondary-button {
  background: #525C7A;
}

.links-secondary-button:hover {
  background-color:#636D8B;
}

.links-ghost-button {
  background: none;
  color: black;
  text-decoration: none;
}

.links-ghost-button:hover {
  text-decoration: underline;
}


@media screen and (max-width: 576px) {
  .links-button {
    width: 264px;
    height: 48px;
    font-size: 16px;
  }
}