.top_banner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 28px;
  grid-template-areas: '. . login';
  background-color: #2B313F;
  color: #ffffff;
  align-items: center;
  padding: 0 2rem;
}

.top_banner * {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .top_banner {
    grid-template-columns: 3rem repeat(3, 1fr) 3rem;
    grid-template-areas: '. cta . login .';
  }
}

@media (min-width: 1024px) {
  .top_banner {
    grid-template-columns: 1fr repeat(3, minmax(260px, 1fr)) 1fr;
  }
}