.download-links {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 25px;
  align-items: center;
}

.download-link {
  text-decoration: none;
  justify-content: center ;
}

.download-badge {
  width: 180px;
}

@media (min-width: 600px) {
  .download-links {
    display: flex;
    flex-direction: row;
  }
}